import './App.css';
import React from 'react';
import {MapContainer, Polyline, Popup, TileLayer, useMapEvents} from "react-leaflet";

function MyComponent() {
    const map = useMapEvents({
        click: (event) => {
            //  map.locate()
        },
        locationfound: (location) => {
            console.log('location found:', location)
        },
    })
    return null
}


class App extends React.Component {

    constructor() {
        super();
        this.state = {
            positions: [],
        }
    }

    async componentDidMount() {
        const response = await fetch("https://rapid-water-79ba.tk7-dev.workers.dev/api");
        let positions = await response.json();
        this.setState({positions: positions.response});
    }

    render() {
        if (!this.state.positions[0]) {
            return (<div>Leer</div>)
        }
        return (
            <div>
                hello
                <MapContainer
                    // for simplicty set center to first gpx point
                    center={this.state.positions[0].values[0]}
                    zoom={11}
                    scrollWheelZoom={true}
                >
                    <MyComponent/>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    {
                        this.state.positions.map((position, key) => {
                            return <Polyline
                                key={key}
                                eventHandlers={{
                                    click: () => {
                                        console.log('marker clicked')
                                    },
                                }}
                                pathOptions={{fillColor: 'red', color: position.type === 'wandern' ? 'blue' : 'red'}}
                                positions={position.values}
                            >
                                <Popup><a href={"http://localhost:3000/" + position.path} target="_blank" rel="noreferrer">Download GPX Datei</a></Popup>
                            </Polyline>
                        })
                    }
                </MapContainer>
            </div>
        );
    }
}

export default App;
